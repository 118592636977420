import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import i18n from '../../i18n'
import generateId from '../../helpers/random-id.helper'

export const providersApi = createApi({
  reducerPath: 'providersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window?.configs?.BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('content-type', 'application/json')
      headers.set('accept', 'application/json')
      headers.set('Accept-Language', i18n.language)
      return headers
    }
  }),
  tagTypes: [
    'PaymentCards',
    'FAQ',
    'TextPages',
    'PaymentInfo',
    'P2pSession',
    'CommissionInfo',
    'Banners'
  ],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => `providers/categories`
    }),
    getProviders: builder.query({
      query: () => `providers`
    }),
    getCards: builder.query({
      query: (providerId) =>
        `payments/cards${providerId ? `?providerId=${providerId}` : ''}`,
      providesTags: ['PaymentCards']
    }),
    getFAQ: builder.query({
      query: () => `FAQs`,
      providesTags: ['FAQ']
    }),
    getTextPages: builder.query({
      query: () => `TextPages`,
      providesTags: ['TextPages']
    }),
    getSocialNetworks: builder.query({
      query: () => `SocialNetworks`
    }),
    getContactInfo: builder.query({
      query: () => `Contact`
    }),
    getCardIcons: builder.query({
      query: () => `PaymentProviders`
    }),
    getOrderInfo: builder.query({
      query: ({ orderCode, token, isDefault }) =>
        `${isDefault ? '' : 'c2c/'}payments/${orderCode}?token=${token}`,
      providesTags: ['PaymentInfo']
    }),
    getBanners: builder.query({
      query: (page) => `banners?page=${page}`,
      providesTags: ['Banners']
    }),
    getCommissionInfo: builder.query({
      query: (providerId) => `payments/commissions/charges/${providerId}`,
      providesTags: ['CommissionInfo']
    }),
    getSession: builder.query({
      query: () => `c2c/session`,
      providesTags: ['P2pSession']
    }),
    getInsuranceProviders: builder.query({
      query: () => `insurance/providers`
    }),

    getBalance: builder.mutation({
      query: (body) => {
        let params = {
          ...body,
          list: [...body.list]?.sort(
            (a, b) => a.name.slice(-1) - b.name.slice(-1)
          )
        }

        return {
          url: `providers/${body?.direct ? 'direct-' : ''}balance${
            body.verify ? '/confirm' : ''
          }`,
          method: 'POST',
          body: params
        }
      },
      transformResponse: (baseQueryReturnValue, meta, arg) => {
        return baseQueryReturnValue?.balanceInformations?.map((obj) => ({
          ...obj,
          balanceCheckArgs: { ...arg, providerId: obj?.providerId },
          uiId: generateId()
        }))
      }
    }),
    getCommissions: builder.mutation({
      query: (body) => {
        return {
          url: `payments/commissions`,
          method: 'POST',
          body
        }
      }
    }),
    getInsurancePrices: builder.mutation({
      query: (body) => {
        return {
          url: `insurance/price`,
          method: 'POST',
          body
        }
      }
    }),
    confirmInsurance: builder.mutation({
      query: (body) => {
        return {
          url: `insurance`,
          method: 'POST',
          body
        }
      }
    }),
    insertPayment: builder.mutation({
      query: (body) => {
        return {
          url: `payments`,
          method: 'POST',
          body
        }
      }
    }),
    sendOtp: builder.mutation({
      query: (body) => {
        return {
          url: `providers/balance/send`,
          method: 'POST',
          body
        }
      }
    }),
    resendOtp: builder.mutation({
      query: (body) => {
        return {
          url: `providers/balance/resend`,
          method: 'POST',
          body
        }
      }
    }),
    sendMail: builder.mutation({
      query: ({ orderCode, isDefault, ...body }) => {
        return {
          url: `${
            isDefault ? '' : 'c2c/'
          }payments/cheque/${orderCode}/send-email`,
          method: 'POST',
          body
        }
      }
    })
  })
})

export const {
  useGetCategoriesQuery,
  useGetProvidersQuery,
  useGetCardsQuery,
  useGetFAQQuery,
  useGetTextPagesQuery,
  useGetCardIconsQuery,
  useGetSocialNetworksQuery,
  useGetSessionQuery,
  useGetCommissionInfoQuery,
  useGetContactInfoQuery,
  useGetBalanceMutation,
  useGetOrderInfoQuery,
  useGetCommissionsMutation,
  useSendMailMutation,
  useGetBannersQuery,
  useInsertPaymentMutation,
  useGetInsurancePricesMutation,
  useGetInsuranceProvidersQuery,
  useConfirmInsuranceMutation,
  useSendOtpMutation,
  useResendOtpMutation
} = providersApi
